@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Black-Web.eot');
  src: url('../public/assets/font/Graphik-Black-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Black-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Black-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-BlackItalic-Web.eot');
  src: url('../public/assets/font/Graphik-BlackItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-BlackItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-BlackItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Bold-Web.eot');
  src: url('../public/assets/font/Graphik-Bold-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Bold-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Bold-Web.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-BoldItalic-Web.eot');
  src: url('../public/assets/font/Graphik-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-BoldItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-BoldItalic-Web.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Extralight-Web.eot');
  src: url('../public/assets/font/Graphik-Extralight-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Extralight-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Extralight-Web.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-ExtralightItalic-Web.eot');
  src: url('../public/assets/font/Graphik-ExtralightItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-ExtralightItalic-Web.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Light-Web.eot');
  src: url('../public/assets/font/Graphik-Light-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Light-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-LightItalic-Web.eot');
  src: url('../public/assets/font/Graphik-LightItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-LightItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Medium-Web.eot');
  src: url('../public/assets/font/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Medium-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-MediumItalic-Web.eot');
  src: url('../public/assets/font/Graphik-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-MediumItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-MediumItalic-Web.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Regular-Web.eot');
  src: url('../public/assets/font/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Regular-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Regular-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-RegularItalic-Web.eot');
  src: url('../public/assets/font/Graphik-RegularItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-RegularItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Semibold-Web.eot');
  src: url('../public/assets/font/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Semibold-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Super-Web.eot');
  src: url('../public/assets/font/Graphik-Super-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Super-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Super-Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-SuperItalic-Web.eot');
  src: url('../public/assets/font/Graphik-SuperItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-SuperItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-SuperItalic-Web.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-Thin-Web.eot');
  src: url('../public/assets/font/Graphik-Thin-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-Thin-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-Thin-Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/assets/font/Graphik-ThinItalic-Web.eot');
  src: url('../public/assets/font/Graphik-ThinItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../public/assets/font/Graphik-ThinItalic-Web.woff2') format('woff2'),
       url('../public/assets/font/Graphik-ThinItalic-Web.woff') format('woff');
  font-weight: 100;
  font-style: italic;
} 

html,
body {
  margin: 0;
  height: 100%;
  background-color: #E5E5E5;
  font-family: 'Graphik';
  overflow:hidden;
}

p {
  line-height: 2vh;
}

p.left-pannel-content {
  margin-block-start: 0em;
  margin-block-end: 2em;
  
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}


/**
 * ==============================================
 * Customization for OMV START
 * ==============================================
 */

.omv-header{
  height: 8vh !important;
  width:100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(2, 38, 89);
  color:#fff;

}

.omv-logo{
  margin-left:8vw;
  margin-top: 0;
  position: relative;
  top:0.5vh;
  cursor:pointer;
    box-sizing: border-box;
    background-color: transparent;
    margin: 0em;
    padding-block: 0;
    padding-inline: 0;
    border-width: 0;
    border-style: none;
    border-color: transparent;
    border-image: none;
  
}

.omv-logo img{
  height:7vh;
}

button.omv-logo{
  margin-left:8vw;
}

.user-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  height: 6.12vh;
  position: relative;
  background: linear-gradient(#E5E5E5 0%, #E5E5E5 100%) 00% padding-box,
              linear-gradient(120deg, #fb4ea2 0%, #0d3cf0 29%,#a415fb 71%,#35fcea 100%)  border-box;
  border-radius: 40vw;
  border: 0.1em solid  #022659;
  box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  align-items: center;
  
}

.product-acceptance-button {
  color:white;
  background-color: rgb(2, 38, 89) !important;
  border-radius: 120px ;
  border: solid 0px #969593;
  font-size: 1.2vh;
  margin-left: 1vw;
  padding: 0vh 2vh 0vh 2vh !important;
  cursor: pointer;
}
.send-button {
  box-sizing: border-box;
  background-color: rgb(2, 38, 89) !important;
  border: none;
  height:80%;
  display:inline-block;
  flex-direction: column;
  border-radius: 40vw;
  width:10vw;
  min-width: 6%;
  cursor:pointer;
  margin-right:0.5vw;
  align-items: center;
}
.send-button:hover {
  box-sizing: border-box;
  background-color: #65F862 !important;
  border: none;
  height:80%;
  display:inline-block;
  flex-direction: column;
  border-radius: 40vw;
  min-width: 6%;
  cursor:pointer;
  margin-right:0.5vw;
  align-items: center;
}

.send-button>svg {
  height: 45%;
}
.send-button:hover>svg {
  height: 45%;
  color: rgb(2, 38, 89);
}

.upload-button {
  box-sizing: border-box;
  background-color: rgb(2, 38, 89) !important;
  border: none;
  border-radius: 40vw;
  height:80%;
  margin-right: 1vw;
  min-width: 4%;
  width:5vw;
}
.upload-button:hover {
  box-sizing: border-box;
  background-color: #65F862 !important;
  border: none;
  border-radius: 40vw;
  height:80%;
  margin-right: 1vw;
  min-width: 4%;
  width:5vw;
}
.upload-button>svg {
  height: 60%;
}
.previous-project-container-side{
  display:flex;
  flex-direction:column;
  font-size: 1.2vh;
  position: absolute;
  top: 75vh;
  margin-left: 0;
  
}

.previous-project-container-side>.product-acceptance-button {
  color:white;
  background-color: rgb(2, 38, 89) !important;
  border-radius: 120px ;
  border: solid 0px #969593;
  font-size: 1.2vh;
  margin-left: 0;
  padding: 0vh 2vh 0vh 2vh !important;
  cursor: pointer;
  height:5vh !important;
  margin-bottom:1vh;
  width:20vw;
}

.demo-headline{
  font-size:1.4em;
  position:fixed;
  color:#fff;
  left: 79vw;
  top:3.4vh;
}
.accept-modal-button, button.cancel-modal-button, .start-agent-conversation-button, .summary-buttons-container button  {
  display: inline-flex;
  flex-direction: row;
  margin: 1vh 2vh 4.5vh 0;
  padding: 1vh 2vh 1vh 2vh;
  background-color: rgb(2, 38, 89) !important;
  border-radius: 80px;
  border: 0.1em solid transparent;
  font-size: 1.5vh;
  color:#fff;
}
.accept-modal-button:hover, button.cancel-modal-button:hover, .start-agent-conversation-button:hover, .summary-buttons-container button:hover  {
  display: inline-flex;
  flex-direction: row;
  margin: 1vh 2vh 4.5vh 0;
  padding: 1vh 2vh 1vh 2vh;
  background-color: #65F862 !important;
  border-radius: 80px;
  border: 0.1em solid transparent;
  font-size: 1.5vh;
  color:rgb(2, 38, 89) !important;
}

.modal .accept-modal-button {
  float:right;
  background-color: rgb(2, 38, 89) !important;
              top: 34vh;
    left: 36.5vw;
    position:absolute;
  /* background:#fff; */
}

.modal .cancel-modal-button  {
  float:right;
  background-color: rgb(2, 38, 89) !important;
              top: 34vh;
    left: 30.5vw;
    position:absolute;
  
}

.close-pdf-button button, .download-pdf-button button  {
  display: block;
  flex-direction: row;
  margin: auto;
  padding: 1vh 0 1vh 0;
  
  background-color: rgb(2, 38, 89) !important;
  border-radius: 20vw;
  border: 0.1em solid transparent;
  font-size: 1.5vh;
  width:5vw;
  text-align:center;
  
}

.modal .accept-modal-button:hover {
  float:right;
  background-color: #65F862 !important;
              top: 34vh;
    left: 36.5vw;
    position:absolute;
  /* background:#fff; */
}

.modal .cancel-modal-button:hover  {
  float:right;
  background-color: #65F862 !important;
              top: 34vh;
    left: 30.5vw;
    position:absolute;
  
}

.close-pdf-button button:hover, .download-pdf-button:hover button  {
  display: block;
  flex-direction: row;
  margin: auto;
  padding: 1vh 0 1vh 0;
  
  background-color: #65F862 !important;
  border-radius: 20vw;
  border: 0.1em solid transparent;
  font-size: 1.5vh;
  width:5vw;
  text-align:center;
  
}

/**
 * ==============================================
 * Customization for OMV END
 * ==============================================
 */

.main-container {
  height: 100%;
  width: 100%;
  /* border: 1px solid black; */
  box-sizing: border-box;
  padding: 11.02vh 6.66vw 8.98vh 6.66vw;
  display: flex;
  flex-direction: row;
}

.left-pannel {
  visibility:hidden;
  width: 17.80vw;
  margin-right: 6.66vw;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2vh;
  display: flex;
  flex-direction: column;
  
}
.left-pannel-slide {
  visibility: visible;
  width: 17.80vw;
  margin-right: 6.66vw;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2vh;
  display: flex;
  flex-direction: column;
  animation: 1s ease-in-out 0s 1 slideInFromLeft;
}

.left-pannel-block {
  padding-bottom:3vh;
}

.left-pannel-title {
  color: rgb(2, 38, 89) !important;
  font-size: 1.6vh;
  font-weight: 500;
  margin-block-start: 0em !important;
  margin-block-end: 0.5em !important;
}

.left-pannel-content {
  font-size: 1.3vh;
  color: #333333;
}

.chat {
  height: 80vh;
  width: 62.22vw;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

}
/*
.user-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  height: 6.12vh;
  position: relative;
  background: linear-gradient(#E5E5E5 0%, #E5E5E5 100%) 00% padding-box,
              linear-gradient(120deg, #fb4ea2 0%, #0d3cf0 29%,#a415fb 71%,#35fcea 100%)  border-box;
  border-radius: 80px;
  border: 0.1em solid transparent;
  box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
*/

.user-input {
  background-color: transparent;
    border: none;
    width: 53vw;
    margin-right: 3vw;
    left: 2vw;
    position: relative;
    font-size: 1.6vh;
}





#animation-gif{
  max-height: 61vw;
  width: 61vw;
  /* height: 61vw;   */
  position:fixed;
}
#animation-gif:after {
  content:""; 
  position: absolute; 
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
  height: 40%;  
}


.messages-container {
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 59.72vw;
  overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-left: 1.5vw;
  }

.messages-container::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.messages-container > :first-child {
  margin-top: auto !important;
}



.message {

  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  font-size: 1.6vh;
  margin-bottom: 3vh;
  width: 60vw;

}

.message > img {
  height: 5vh;
  margin-left: 2vh;

}

.message-content {
  background-color: white;
  margin-bottom: 1vh;
  margin-left: 3vh;
  margin-right: 3vh;
  padding: 0.5vh 1vh 0.5vh 2vh;
  box-sizing: border-box;
  border-radius: 0px 20px 20px 20px;
  font-size: 1.6vh;

}

.message-content-assistant {
  background-color: transparent;

}

.message-content-user {
  background-color: #D9D9D9;
  margin-left: auto;
  margin-right: 0px;
  /* max-width: 30%; */
  padding: 3vh;
  border-radius: 2vh 0 2vh 2vh;

}

.message-with-icon {
  margin-right: 0px;
  margin-left: auto;
  display: flex;
  flex-direction: row;

}

.previous-project-container{
  display: flex;
  flex-direction: row;
  font-size: 1.2vh;
  position: absolute;
  top: 82vh;
  margin-left: 1.2vw;
}
/*
.product-acceptance-button {
  background-color: transparent;
  border-radius: 120px;
  border: solid 1px #969593;
  font-size: 1.2vh;
  margin-left: 1vw;
  padding: 1vh 2vh 1vh 2vh;
}
  */

.modal-container{
  content: "";
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 10; */
}

.modal {
  overflow:hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 27vh;
  left: 40vw;
  height: 36vh;
  width: 40vw;
  /* transform: translate(-50%, -50%); */
  background-color: white;
  padding: 3vh;
  border-radius: 2vh;
  font-size: 1.6vh;
  font-weight: 300px;
  /* box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow:  -3px 2px 100px rgba(186, 172, 222, 1), 20px 62px 1000px 0 rgba(0, 0, 0, 1);
  animation: 1s ease-in-out 0s 1 slideInFromBottom;

}
.modal-report {
  overflow:hidden;
  position: fixed;
  display: flex;
  top: 7vh;
  left: 28vw;
  height: 75vh;
  width: 55vw;
  /* transform: translate(-50%, -50%); */
  background-color: white;
  padding: 9vh 3vh 4vh 3vh;
  border-radius: 2vh;
  font-size: 1.6vh;
  font-weight: 300px;
  box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: 1s ease-in-out 0s 1 slideInFromBottom;
  z-index: 10;
  box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal > h3 {
  font-weight: 500;
}

.modal-images{
  display: flex;
  flex-direction: row;
  height: 30%;
  margin-right: 3vh;
  margin-bottom: 3vh;
}

.modal-images > img{
  margin-right: 3vh;
  width: 5vw;
  height: 5vw;
}

.modal-briefs > img{
  margin-right: 1vh;
  width: 1.77vw;
  height: 1.77vw;
  padding-top: 1.2vh;
}


.modal-briefs{
  display: flex;
  flex-direction:row;
  height: 30%;
  margin-right: 3vh;
  margin-bottom: 3vh;
  width: 45%;
  height: 6vh;
  position:relative;
  transition: transform 0.3s ease;
}

.modal-briefs-selected{
  transform:scale(1.1);
  border:0.2em solid rgba(109, 41, 155, 0.925);
  box-shadow:0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2vh;
  padding:1vh;
  height: 5.8vh;
  transition: transform 0.3s ease;
  /* width: 12.5vw; */

}

.brief-title {
  margin-block-start: 1.2em !important;
    margin-block-end: 0em !important;
}


.modal-brief > img{
  height: 30%;
}

.modal-brief::after{
  border: 1px solid black;
}

.brief-with-title{
  display: flex;
  flex-direction: column;
  max-height: 30px;
}

.modal-images > img {
    transition: transform 0.3s ease; /* Add transition for smooth effect */
    border-radius: 1vh;
}


.modal-images > img.clicked {
transform: scale(1.2); 
    border: 0.2em solid rgba(109, 41, 155, 0.925); 
    box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius:2vh;
} 


.modal-briefs > img {
  transition: transform 0.3s ease; /* Add transition for smooth effect */

}
/* 
.modal-briefs > img.clicked {
  transform: scale(1.2); 
  border: 0.2em solid rgba(109, 41, 155, 0.925); 
    box-shadow: 0 4px 8px 0 rgba(186, 172, 222, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius:2vh;
    padding:0.5vw;
} */

.left-pannel-files-contaienr {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.left-pannel-files-row {
  display: flex;
    flex-direction: row;
    border: 1px solid #B3B2B5;
    border-radius: 3vh;
    margin-bottom: 1vh;
    padding: 0;
}

.left-pannel-files-row img {
  width: 1.77vw;
  height: 1.77vw;
  margin-right: 0.5vh;
  object-fit: contain;
  /* overflow-clip-margin:unset !important;
    overflow:unset !important; */
    padding: 0.5vh;
    border-radius: 5vh;
    
}
.left-pannel-files-row .roundIcon > img {
  width: 1.77vw;
  height: 1.77vw;
  margin-right: 0.5vh;
  object-fit: contain;
  overflow-clip-margin:unset !important;
    overflow:unset !important;
    
    
}
.left-pannel-files-filename{
  margin-block-start: 0.8em !important;
    margin-block-end: 0em !important;
}
.roundIcon{
  overflow:hidden;
  position:relative;
  border-radius:50%;
  width: 1.77vw;
  height: 1.77vw;
  padding: 0.5vh;
}


.proposed-agents-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 5vh;
}

.proposed-agents-row {
  display: flex;
  flex-direction: row;
}

.agent-card {
  width:18.88vw;
  height:24.40vh;
  padding: 0 2vh 4vh 0;
  font-size: 1.4vh;
  
}

.agent-card-header {
  height: 5vh;
  width:18.88vw;
  display: flex;
  flex-direction: row;
  background-color: #D9D9D9;
  font-size: 1.4vh;
  /* Rectangle 1285 */
  box-sizing: border-box;
  border-radius: 1vw 1vw 0 0;
  padding: 1vh;

}
.agent-card-footer {
  height: 3vh;
  width:18.88vw;
  display: flex;
  flex-direction: row;
  background-color: #f6f6f6;
  font-size: 1.2vh;
  /* Rectangle 1285 */
  box-sizing: border-box;
  border-radius: 0 0 1vw 1vw ;
  padding: 1vh 0 0 1.5vh;
  color:rgb(159, 159, 159) !important;
  

}
.agent-card-header img{
  width:1.77vw;
  height:1.77vw;
}
.agent-card-header .agent-name{
  width:5vw;
  left: 0.7vh;
  top: 0.0vh;
  
  position: relative;
}
.agent-card-header .agent-role{
  text-align:right;
  float:right;
  position: relative;
  right:-1.5vw;
  top: 0.0vh;
  width:9vw;
  font-weight:500;
}

.agent-card-content {
  height:16vh;
  background-color: white;
  border-radius:  0 0 0 0;
  padding: 1vh;

}
.agent-card-content-title{
  font-weight:500;
}

.agent-skills-tasks{
  margin-bottom:1vh;
}



.add-another-agent {
  width: 18.88vw;
    height: 16.40vh;
    color: #B3B2B5;
    border-radius: 1vw 1vw 1vw 1vw;
    border: 0.1vw solid #B3B2B5;
    font-size: 10vh;
    font-weight: 500;
    padding: 3.5vw 0 0 0;
    text-align: center;
  }

.left-pannel-team-row {
  display: flex;
  flex-direction: row;
  border: 1px solid #B3B2B5;
  border-radius: 3vh;
  margin-bottom: 1vh;
  padding: 0.5vh;
  align-items: center;
}
.left-pannel-team-row img{
  width: 1.77vw;
  height: 1.77vw;
  
}
.left-pannel-agent-name{
  padding-left: 1vw;
  padding-top: 0.6vh;
}

div.line-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 1.6vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
  position: -webkit-sticky;
  position: sticky;
  top: 5vh;
  
}

.line-with-text hr{
  flex-grow: 1;
  border: 0;
  border-top: 2px solid rgb(2, 38, 89) !important;
  margin: 0 10px;
}

.line-with-text span {
  color: rgb(2, 38, 89) !important;
  font-weight: bold;
}

.final-block-container{
  width: 100%
}

.final-line-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 1.6vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.final-line-with-text hr {
  flex-grow: 1;
  border: 0;
  border-top: 2px solid rgb(2, 38, 89) !important;
  margin: 0 10px;
}

.final-line-with-text span {
  color: rgb(2, 38, 89) !important;
  font-weight: bold;
}

.agents-messages-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.manager-message-content {
  color: #3E3B3B;
  font-size: 1.6vh;
  margin-bottom: 3vh;
}
.manager-message-content img, .message-content img{
  width:10vw;
  border-radius:2vh;
  padding:0.5vh;
}


.image-row-user-message {
  display: block;
  /* flex-direction: row; */
  max-width: 100%;
  

}

.image-with-title-message> img {
  width: 1.77vw;
    margin-right: 0.0vh;
    padding: 0.9vh;
    border-radius: 3vh;
    height: 1.77vw;

}

.image-with-title-message {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  display: flex;
  border: 1px solid #B3B2B5;
  border-radius: 5vh;
  margin-bottom:1vh;
  
}
.image-with-title-message p{
  padding:0 1vw 0 0vw;
}

.summary-container {
  display: flex;
  flex-direction: column;
}

.summary-card {
align-self: center;
width: 100%;
}

.summary-card-header{
/* background-color: #E5E5E5;
font-size: 1.6vh;
border: #E5E5E5; */

height: 5vh;
  /* width:18.88vw; */
  display: flex;
  flex-direction: row;
  background-color: #D9D9D9;
  font-size: 2vh;
    font-weight: 300;
    box-sizing: border-box;
    border-radius: 1vw 1vw 0 0;
    padding: 1.5vh 0 0 1.5vw;
}

.summary-card-content{
background-color: white;
font-size: 2vh;
padding:1vh 3vh 2vh 3vh;
border-radius:0 0 2vh 2vh;
font-size:1.6vh;
}

.summary-card-image-row{
  max-width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 3.2vw;
}

.summary-card img {
  width: 15vh;
  margin-right:2vw;
  border-radius: 2vh;
}

.summary-buttons-container {
  padding:2vh 0 5vh 0;
}

button {
  font-family: "Graphik" !important;
  cursor: pointer;
  
}




/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */
 
 /* Loading animation CSS */
.loading-dots {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 24px;
}

.loading-dots div {
  position: absolute;
  top: -2vh;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #460073;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  margin-bottom:5vh;
}

.loading-dots div:nth-child(1) {
  left: 8px;
  animation: loading-dots1 0.6s infinite;
}

.loading-dots div:nth-child(2) {
  left: 8px;
  animation: loading-dots2 0.6s infinite;
}

.loading-dots div:nth-child(3) {
  left: 32px;
  animation: loading-dots2 0.6s infinite;
}

.loading-dots div:nth-child(4) {
  left: 56px;
  animation: loading-dots3 0.6s infinite;
}


@keyframes loading-dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loading-dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes loading-dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}

/**
 * ==============================================
 * PDF
 * ==============================================
 */

/* For pdf */
.react-pdf__Page {
  /* margin-top: 10px; */
  /* padding: 27vh; */
  display: flex;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  /* scale: 1; */
  width: 100% !important;
  height: auto !important;
  position: relative;
  left: 0vw !important;
  top: 0 !important;
}

/* For all pages */
.all-page-container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  scrollbar-width: none;
}

.all-page-container a {
  position: fixed;
  z-index: 50;
}

.download-pdf-button {
  position: absolute;
  top: 2vh;
  right: 14.5vw;
}

.close-pdf-button {
  position: absolute;
  top: 2vh;
  right: 6.8vw;
}

.react-pdf__Page__canvas {
  
  margin-bottom: 2vh !important;
}